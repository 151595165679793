import {combineComponents} from 'utils/combineComponents';

import {AuthProvider} from 'contexts/AuthContext';
import {ConsentProvider} from 'contexts/ConsentContext';
import {ViewportProvider} from 'contexts/ViewportContext';
import {PageProvider} from 'contexts/PageContext';
import {ToastProvider} from 'contexts/ToastContext';
import {SidebarProvider} from 'contexts/SidebarContext';
import {CommonDataProvider} from 'contexts/CommonDataContext';
import {LocaleProvider} from 'contexts/LocaleContext';
import {CaptchaProvider} from 'contexts/CaptchaContext';
import {MailingProvider} from 'contexts/MailingContext';
import {ProgressProvider} from 'contexts/ProgressContext';
import {SubscriptionProvider} from 'contexts/SubscriptionContext';
import {VerificationProvider} from 'contexts/VerificationContext';
import {LimitsProvider} from 'contexts/LimitsContext';
import {GlobalCountProvider} from 'contexts/GlobalCountContext';
import {LockedFeatureProvider} from 'contexts/LockedFeatureContext';
import {ActivityProvider} from 'contexts/ActivityContext';
import {MembershipProvider} from 'contexts/MembershipContext';
import {HeatmapProvider} from 'contexts/HeatmapContext';

const providers = [
  ConsentProvider,
  AuthProvider,
  MembershipProvider,
  SubscriptionProvider,
  LockedFeatureProvider,
  ViewportProvider,
  PageProvider,
  GlobalCountProvider,
  SidebarProvider,
  CommonDataProvider,
  LocaleProvider,
  CaptchaProvider,
  ToastProvider,
  ProgressProvider,
  MailingProvider,
  LimitsProvider,
  VerificationProvider,
  ActivityProvider,
  HeatmapProvider,
];
const AppContextProvider = combineComponents(...providers);

export default AppContextProvider;
